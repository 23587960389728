// routing
import Routes from 'routes';

// import { BrowserRouter } from 'react-router-dom';
// project imports
import Locales from 'ui-component/Locales';
// import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
// import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
// import { usePrompt } from './hooks';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';
// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// ==============================|| APP ||============================== //
const getUserConfirmation = (message: any, callback: any) => {
    // this is the default behavior
    const allowTransition = window.confirm(message);
    callback(allowTransition);
};
const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                {/* <NavigationScroll> */}
                <AuthProvider>
                    <>
                        <Routes />
                        {/* <Snackbar /> */}
                    </>
                </AuthProvider>
                {/* </NavigationScroll> */}
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
