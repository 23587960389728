// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import checkToken from 'utils/checkToken';
import { boolean, string } from 'yup/lib/locale';
import { dispatch } from '../../index';
import { LOGOUT } from 'store/actions';
import { urlUse } from '../../../config';

// const localIpUrl = require('local-ip-url');
// import localIpUrl from 'local-ip-url';
// const urlSTR2 = 'https://cppapi.nitisakc.dev';
// const urlSTR2 = 'http://192.168.55.150:4000';
const urlSTR2 = urlUse;
const imageDB = 'KPRAUD';
// const urlSTR = 'https://cppapi.nitisakc.dev';
// ----------------------------------------------------------------------
const initialState = {
    error: null,
    nodesMST: { sucess: boolean, data: [] },
    propertiesMST: { sucess: boolean, paramiter: string, data: [] },
    flowMST: { sucess: boolean, data: [] },
    tempFlow: { sucess: boolean, data: [] },
    flowStep: { sucess: boolean, data: [] },
    stepPorperties: { sucess: boolean, data: [] },
    calcAll: { sucess: boolean, data: [] },
    submit: { sucess: boolean, data: [] },
    calcSave: { sucess: boolean, data: [] },
    doc: { sucess: boolean, data: [] },
    docByID: { sucess: boolean, data: [] },
    rq: { sucess: boolean, data: [] },
    referDoc: { sucess: boolean, data: [] },
    porpertiesByID: { sucess: boolean, data: [] },
    referQuo: { sucess: boolean, data: [] },
    propertiesByRQ: { sucess: boolean, data: [] },
    gBase64: { data: '' },
    paperPrice: { sucess: boolean, data: { name: string, data: [] } },
    rmPrice: { sucess: boolean, data: { name: string, data: [] } },
    userName: { sucess: boolean, data: [] },
    quo: { sucess: boolean, data: [] },
    flowPrice: { sucess: boolean, data: [] },
    filterPaper: { sucess: boolean, data: [] },
    prepressByNode: { sucess: boolean, data: [] },
    exportExcel: { sucess: boolean, data: [] },
    hist: { sucess: boolean, data: [] },
    fileDrawingDetailSuccess: {}
};

const slice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state: any, action: any) {
            state.error = action.payload;
        },

        // MASTER PROCESS
        getNodeMSTSuccess(state: any, action: any) {
            // console.log(state);
            state.nodesMST = action.payload;
        },

        getPropertiesMSTSuccess(state: any, action: any) {
            state.propertiesMST = action.payload;
        },

        getPrepressByNodeSuccess(state: any, action: any) {
            state.prepressByNode = action.payload;
        },

        setFlowMSTSuccess(state: any, action: any) {
            state.flowMST = action.payload;
        },

        getTempFlowSuccess(state: any, action: any) {
            state.tempFlow = action.payload;
        },

        getStepByFlowSuccess(state: any, action: any) {
            state.flowStep = action.payload;
        },

        getStepPorpertiesSuccess(state: any, action: any) {
            state.stepPorperties = action.payload;
        },

        getCalcAll(state: any, action: any) {
            state.calcAll = action.payload;
        },

        getExportExcel(state: any, action: any) {
            state.exportExcel = action.payload;
        },
        postSubmit(state: any, action: any) {
            state.submit = action.payload;
        },
        postCalcSave(state: any, action: any) {
            state.calcSave = action.payload;
        },
        postRQSave(state: any, action: any) {
            state.calcSave = action.payload;
        },

        getDocSuccess(state: any, action: any) {
            state.doc = action.payload;
        },
        getDocByIDSuccess(state: any, action: any) {
            state.docByID = action.payload;
        },

        getRQSuccess(state: any, action: any) {
            state.rq = action.payload;
        },

        getReferDocSuccess(state: any, action: any) {
            state.referDoc = action.payload;
        },

        getPorpertiesByIDSuccess(state: any, action: any) {
            state.porpertiesByID = action.payload;
        },

        getReferQuoSuccess(state: any, action: any) {
            state.referQuo = action.payload;
        },
        getPropertiesByRQSuccess(state: any, action: any) {
            state.propertiesByRQ = action.payload;
        },
        getBase64Success(state: any, action: any) {
            state.gBase64 = action.payload;
        },
        getPaperPriceSuccess(state: any, action: any) {
            state.paperPrice = action.payload;
        },
        getRMPriceSuccess(state: any, action: any) {
            state.rmPrice = action.payload;
        },
        getUserNameSuccess(state: any, action: any) {
            state.userName = action.payload;
        },
        getQuoSuccess(state: any, action: any) {
            state.quo = action.payload;
        },
        getFlowPriceSuccess(state: any, action: any) {
            state.flowPrice = action.payload;
        },
        getFilterPaperSuccess(state: any, action: any) {
            state.filterPaper = action.payload;
        },
        getHistSuccess(state: any, action: any) {
            state.hist = action.payload;
        },
        getFileDrawingDetailSuccess(state: any, action: any) {
            state.fileDrawingDetailSuccess = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getBase64(idBase64: string) {
    return async () => {
        try {
            // console.log(`-${pNodeID}-`);
            const data = await checkToken();
            if (data && idBase64) {
                const response = await axios.post(
                    `${urlSTR2}/api/tools/image/gettempimage`,
                    {
                        DB: imageDB,
                        id: idBase64
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getBase64Success(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getUApi() {
    return urlSTR2;
}

export function getNodesMST() {
    // const urlSTR = 'http://localhost:4000';
    // console.log(ip.address());
    return async () => {
        try {
            const response = await axios.get(`${urlSTR2}/api/jobo/calpriceprod/getNodesMST`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || '' }
            });
            // console.log(response);
            dispatch(slice.actions.getNodeMSTSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPorpertiesMST(pNodeID: string) {
    return async () => {
        try {
            // console.log(`-${pNodeID}-`);
            // const data = await checkToken();
            // console.log(data);
            if (pNodeID) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getPropertiesMST`,
                    {
                        nodeID: pNodeID
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );

                dispatch(slice.actions.getPropertiesMSTSuccess(response.data));
            }
        } catch (error) {
            // console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPrepressByNode(pNodeID: string) {
    return async () => {
        try {
            // console.log(`-${pNodeID}-`);
            // const data = await checkToken();
            console.log(pNodeID);
            if (pNodeID) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getPrepressByNode`,
                    {
                        nodeID: pNodeID
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );

                dispatch(slice.actions.getPrepressByNodeSuccess(response.data));
            }
        } catch (error) {
            // console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function setFlowMST(flowID: string, flowMSTName: string, flowDesc: string, flowView: any, flowProperties: any) {
//     // console.log(flowMSTName);
//     // console.log(flowView);
//     // console.log(flowProperties);
//     return async () => {
//         try {
//             const data = await checkToken();
//             if (data) {
//                 const response = await axios.post(
//                     `${urlSTR}/api/calpriceprod/setFlowMST`,
//                     {
//                         FlowID: flowID,
//                         FlowMSTName: flowMSTName,
//                         FlowDesc: flowDesc,
//                         FlowView: flowView,
//                         FlowProperties: flowProperties
//                     },
//                     {
//                         headers: {
//                             // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
//                             Authorization: `Bearer ${localStorage .getItem('serviceToken')}` || ''
//                         }
//                     }
//                 );
//                 dispatch(slice.actions.setFlowMSTSuccess(response.data));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function getTeampFlow(asText: string) {
//     // console.log('pNodeID');
//     return async () => {
//         try {
//             const data = await checkToken();
//             if (data) {
//                 const response = await axios.post(
//                     `${urlSTR}/api/calpriceprod/getTeampFlow`,
//                     {
//                         text: asText
//                     },
//                     {
//                         headers: {
//                             // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
//                             Authorization: `Bearer ${localStorage .getItem('serviceToken')}` || ''
//                         }
//                     }
//                 );
//                 dispatch(slice.actions.getTempFlowSuccess(response.data));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function getStepByFlow(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getStepByFlow`,
                    {
                        flow: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getStepByFlowSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// export function getPoroertiesByStep(asText: string) {
//     // console.log('pNodeID');
//     return async () => {
//         try {
//             const data = await checkToken();
//             if (data) {
//                 const response = await axios.post(
//                     `${urlSTR}/api/calpriceprod/getPoroertiesByStep`,
//                     {
//                         step: asText
//                     },
//                     {
//                         headers: {
//                             // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
//                             Authorization: `Bearer ${localStorage .getItem('serviceToken')}` || ''
//                         }
//                     }
//                 );
//                 // console.log(response.data);
//                 dispatch(slice.actions.getStepPorpertiesSuccess(response.data));
//             }
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function getExportExcel(modeT: string, dataT: any, dataHead: any) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log({
            //     mode: modeT,
            //     data: dataT,
            //     dataH: dataHead
            // });
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/exportExcel`,
                    {
                        mode: modeT,
                        data: dataT,
                        dataH: dataHead
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response.data);
                dispatch(slice.actions.getExportExcel(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCalcAll(modeT: string, dataT: any, dataHead: any) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log({
            //     mode: modeT,
            //     data: dataT,
            //     dataH: dataHead
            // });
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/calcAll`,
                    {
                        mode: modeT,
                        data: dataT,
                        dataH: dataHead
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response.data);
                dispatch(slice.actions.getCalcAll(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postSubmit(headT: any) {
    return async () => {
        let data: boolean = false;
        try {
            data = await checkToken();
            if (!data) dispatch({ type: LOGOUT });
            console.log({
                head: headT
            });
            // if (data) {
            const response = await axios.post(
                `${urlSTR2}/api/jobo/calpriceprod/submit`,
                {
                    head: headT
                },
                {
                    headers: {
                        // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                        Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                    }
                }
            );
            dispatch(slice.actions.postSubmit(response.data));
            // } else {
            //     dispatch(slice.actions.);
            // }
        } catch (error) {
            if (data === false) console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postCalcSave(modeT: string, dataT: any, headT: any) {
    return async () => {
        let data: boolean = false;
        try {
            data = await checkToken();
            if (!data) dispatch({ type: LOGOUT });
            console.log({
                mode: modeT,
                data: dataT,
                head: headT
            });
            // if (data) {
            const response = await axios.post(
                `${urlSTR2}/api/jobo/calpriceprod/calcSave`,
                {
                    mode: modeT,
                    data: dataT,
                    head: headT
                },
                {
                    headers: {
                        // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                        Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                    }
                }
            );
            dispatch(slice.actions.postCalcSave(response.data));
            // } else {
            //     dispatch(slice.actions.);
            // }
        } catch (error) {
            if (data === false) console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postRQSave(modeT: string, dataT: any, headT: any) {
    return async () => {
        let data: boolean = false;
        try {
            data = await checkToken();
            if (!data) dispatch({ type: LOGOUT });
            console.log({
                mode: modeT,
                data: dataT,
                head: headT
            });
            // if (data) {
            const response = await axios.post(
                `${urlSTR2}/api/jobo/calpriceprod/RQSave`,
                {
                    mode: modeT,
                    data: dataT,
                    head: headT
                },
                {
                    headers: {
                        // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                        Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                    }
                }
            );
            dispatch(slice.actions.postRQSave(response.data));
            // } else {
            //     dispatch(slice.actions.);
            // }
        } catch (error) {
            if (data === false) console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDoc(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getDoc`,
                    {
                        text: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getDocSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDocByID(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getDocByID`,
                    {
                        text: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getDocByIDSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRQ(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getRQ`,
                    {
                        text: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getRQSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getReferDoc(asDoc: string, asCust: string, asProd: string, asSale: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getReferDoc`,
                    {
                        doc: asDoc,
                        cust: asCust,
                        prod: asProd,
                        sale: asSale
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getReferDocSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPorpertiesByID(asNodeID: string, asPorpertiesID: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getPoroertiesByID`,
                    {
                        nodeID: asNodeID,
                        porpertiesID: asPorpertiesID
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getPorpertiesByIDSuccess(response.data));
            }
        } catch (error) {
            // console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getReferQuo(asQuo: string, asCust: string, asProd: string, asSale: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getReferQuo1');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getReferQuo`,
                    {
                        quo: asQuo,
                        cust: asCust,
                        prod: asProd,
                        sale: asSale
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getReferQuoSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPropertiesByRQ(as_rqID: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getPropertiesByRQ');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getPropertiesByRQ`,
                    {
                        rqID: as_rqID
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getPropertiesByRQSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPaperPrice(as_text: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getPropertiesByRQ');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getPaperPrice`,
                    { TEXT: as_text },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getPaperPriceSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFlowPrice(as_text: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getPropertiesByRQ');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getFlowPrice`,
                    { dataFlow: as_text },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getFlowPriceSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRMPrice(as_nid: string, as_ppid: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getPropertiesByRQ');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getRMPrice`,
                    { NODE_ID: as_nid, PROPERTIES_ID: as_ppid },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getRMPriceSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserName(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getUserName`,
                    {
                        text: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getUserNameSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getQuo(asQuo: string, asCust: string, asSale: string) {
    return async () => {
        try {
            const data = await checkToken();
            // console.log('getReferQuo1');
            // console.log(data);
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getQuo`,
                    {
                        quo: asQuo,
                        cust: asCust,
                        sale: asSale
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                // console.log(response);
                dispatch(slice.actions.getQuoSuccess(response.data));
                // console.log(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFilterPaper() {
    return async () => {
        try {
            const response = await axios.get(`${urlSTR2}/api/jobo/calpriceprod/getFilterPaper`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || '' }
            });
            // console.log(response);
            dispatch(slice.actions.getFilterPaperSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHist(asText: string) {
    // console.log('pNodeID');
    return async () => {
        try {
            const data = await checkToken();
            if (data) {
                const response = await axios.post(
                    `${urlSTR2}/api/jobo/calpriceprod/getHist`,
                    {
                        DOC_ID: asText
                    },
                    {
                        headers: {
                            // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                            Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                        }
                    }
                );
                dispatch(slice.actions.getHistSuccess(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFileDrawingDetail(base64: string) {
    return async () => {
        try {
            const response = await axios.post(
                `https://api.kimpai.com/impact-pdf/upload`,
                {
                    base64_pdf: base64
                },
                {
                    headers: {
                        // Authorization: 'Bearer ' + localStorage .getItem('serviceToken') || ''
                        Authorization: `Bearer ${localStorage.getItem('serviceToken')}` || ''
                    }
                }
            );
            // console.log(response);
            dispatch(slice.actions.getFileDrawingDetailSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
