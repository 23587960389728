import { lazy } from 'react';
// import { useLocation } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayoutCal from 'layout/MainLayoutCal';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
// import { string } from 'yup';
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const CalpriceprodSalePage = Loadable(lazy(() => import('views/calpriceprod-page/indexsale')));
// const CalpriceprodMasterflowPage = Loadable(lazy(() => import('views/calpriceprod-page/masterflow')));
// interface LazyComponentProps {
//     prop1: string;
//     prop2: string;
// }
// const CalpriceprodDataPage = Loadable<LazyComponentProps>(lazy(() => import('views/calpriceprod-page')));
// const CalpriceprodDataPage = lazy(() => import('views/calpriceprod-page'));
const CalpriceprodDataPage = Loadable(lazy(() => import('views/calpriceprod-page')));
// const location = useLocation();
// console.log('location.pathname');
// localStorage.setItem('urlCurrent', location.pathname);
// const params = new URLSearchParams(window.location.pathname);
// const LoadableLazyComponent: React.FC<LazyComponentProps> = Loadable(lazy(() => import('views/calpriceprod-page')));
// searchParams.get("__firebase_request_key")
// console.log(params);
// const LoadableWithProps: React.FC<LazyComponentProps> = ({ prop1, prop2 }) => {
//     const LoadableLazyComponent = Loadable(lazy(() => import('views/calpriceprod-page')));

//     return <LoadableLazyComponent />;
// };

// let asId: string = '';
// let rdId: string = '';
// const xxx = () => {
//     const queryParameters = new URLSearchParams(window.location.search);
//     asId = queryParameters.get('userId') || '';
//     rdId = queryParameters.get('userId') || '';
// };
// xxx();
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            {/* <MainLayout /> */}
            <MainLayoutCal />
            {/* <CalpriceprodDataPage /> */}
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/',
        //     element: <CalpriceprodDataPage />
        // },
        // {
        //     path: '/',
        //     element: <LoadableLazyComponent prop1={asId} prop2={rdId} />
        // },
        // {
        //     path: '/sample-page',
        //     element: <SamplePage />
        // },
        // {
        //     path: '/calpriceprod-master-page',
        //     element: <CalpriceprodMasterPage />
        // },
        // {
        //     path: '/calpriceprod-master-flow-page',
        //     element: <CalpriceprodMasterflowPage />
        // },
        {
            path: '/calpriceprod-data-page',
            element: <CalpriceprodDataPage />
        },
        {
            path: '/calpriceprod-sale-page',
            element: <CalpriceprodSalePage />
        }
    ]
};

export default MainRoutes;
