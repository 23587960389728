import { Outlet, useLocation } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const location = useLocation();
    localStorage.setItem('urlCurrent', location.pathname);
    // console.log(location.pathname);
    return (
        <>
            <Outlet />
        </>
    );
};

export default MinimalLayout;
