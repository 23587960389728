import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const location = useLocation();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    // const history = useHistory();

    // console.log('demo');

    useEffect(() => {
        if (isLoggedIn) {
            // console.log(location);
            // console.log(localStorage.getItem('urlCurrent'));
            // navigate(DASHBOARD_PATH, { replace: true });
            navigate(localStorage.getItem('urlCurrent') || DASHBOARD_PATH, { replace: true });
            // navigate('/', { state: { previousPath: location } });
            // navigate(-1);
        }
    }, [isLoggedIn, navigate]);

    return children;
};

export default GuestGuard;
