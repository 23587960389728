import { refreshToken, verifyToken } from 'contexts/JWTContext';
import jwtDecode from 'jwt-decode';
import { UserProfile } from 'types/user-profile';

const checkedToken = async (): Promise<boolean> => {
    // window.location.reload();
    const serviceToken = window.localStorage.getItem('serviceToken');
    if (serviceToken == null) {
        window.location.reload();
        return false;
    }
    const verifyRes = await verifyToken(serviceToken);
    // const verifyRes = 'OK';
    if (verifyRes === 'Expired') {
        await refreshToken();
        const newServiceToken = window.localStorage.getItem('serviceToken');
        if (newServiceToken == null) window.location.reload();
        return true;
    }
    try {
        jwtDecode<UserProfile>(serviceToken || '');
        return true;
    } catch (err) {
        window.location.reload();
        // console.log('checkedToken', err);
        return false;
    }
};
export default checkedToken;
