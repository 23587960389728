import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
// import macaddress from 'macaddress';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { InitialLoginContextProps, JWTContextType, JWTDecodeType } from 'types/auth';
import { UserProfile } from 'types/user-profile';
import { urlUse } from '../config';

const chance = new Chance();
// const urlSTR = 'https://cppapi.nitisakc.dev';
const urlSTR = urlUse;
// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export type VerifyTokenResponse = 'Expired' | 'OK' | 'Wrong';
export const verifyToken: (st: string | null) => Promise<VerifyTokenResponse> = async (serviceToken) => {
    if (!serviceToken || serviceToken === null) {
        return 'Wrong';
    }
    const decodeToken = jwtDecode<JWTDecodeType>(serviceToken);
    // console.log(decodeToken);
    if (decodeToken && decodeToken.exp < Date.now() / 1000 - 20) {
        return 'Expired';
    }
    // const response = await axios.post(
    //     `${urlSTR}/api/tools/verify`,
    //     {},
    //     {
    //         headers: {
    //             Authorization: `Bearer ${serviceToken}`
    //         }
    //     }
    // );
    const response = await axios.post(
        `${urlSTR}/JwtVerify`,
        {},
        {
            headers: {
                Authorization: `Bearer ${serviceToken}`
            }
        }
    );
    // console.log(response);
    if (response.data.expired === 'true') return 'Expired';
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return response.data.expired === 'false' ? 'OK' : 'Wrong';
    // return 'OK';
};

export const refreshToken = async () => {
    const response = await axios.post(
        `${urlSTR}/api/tools/refresh-token`,
        {},
        {
            headers: {
                'x-refresh-token': localStorage.getItem('serviceToken') || ''
                // 'Access-Control-Allow-Headers': '*',
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': '*'
            }
        }
    );
    const { token: serviceToken } = response.data;
    setSession(serviceToken);
    setRefreshToken(serviceToken);
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        // window.localStorage .clear();
        delete axios.defaults.headers.common.Authorization;
    }
};

const setRefreshToken = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('refreshToken', serviceToken);
        axios.defaults.headers.common['x-refresh-token'] = serviceToken;
    } else {
        localStorage.removeItem('refreshToken');
        // window.localStorage .clear();
        delete axios.defaults.headers.common['x-refresh-token'];
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);
export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                // const serviceToken =
                //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU0MDAwOCIsIm5hbWUiOiLguKLguIfguKLguKgg4LiX4Li04Lie4Lii4LmM4LmA4Lil4Lit4LmA4Lil4Li04LioIiwidW5pdElkIjoiQjAwIiwib3JnIjoiT1BQIiwicm9sZSI6IiIsInRyYWNraW5nU3RhdHVzIjoiVCIsImlhdCI6MTY1OTQ5MjYyOCwiZXhwIjoxNjU5NDkyNjg4fQ.ZTdl-DP8JyjhXqMCalwLqzyrirCqXqkEagBF4dDy8HM';
                // console.log(serviceToken);
                // const userInfo = jwtDecode<UserProfile>(serviceToken || '');
                // console.log(userInfo);
                const dispatchContext = (verify: string) => {
                    if (serviceToken && verify === 'OK') {
                        setSession(serviceToken);
                        const user = jwtDecode<UserProfile>(serviceToken);

                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user
                            }
                        });
                    } else {
                        dispatch({
                            type: LOGOUT
                        });
                    }
                };
                // console.log('verifyRes');
                let verifyRes = await verifyToken(serviceToken);
                // let verifyRes = 'OK';
                // testVerifyToken
                // const testToken =
                //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU0MDAwOCIsIm5hbWUiOiLguKLguIfguKLguKgg4LiX4Li04Lie4Lii4LmM4LmA4Lil4Lit4LmA4Lil4Li04LioIiwidW5pdElkIjoiQjAwIiwib3JnIjoiT1BQIiwicm9sZSI6IiIsInRyYWNraW5nU3RhdHVzIjoiVCIsImlhdCI6MTY1OTQ5MjYyOCwiZXhwIjoxNjU5NDkyNjg4fQ.ZTdl-DP8JyjhXqMCalwLqzyrirCqXqkEagBF4dDy8HM';
                // let verifyRes = await verifyToken(testToken);

                if (verifyRes === 'Expired') {
                    // console.log('refreshToken');
                    // console.log(localStorage .getItem('refreshToken'));
                    await refreshToken();
                    const newServiceToken = window.localStorage.getItem('serviceToken');
                    verifyRes = await verifyToken(newServiceToken);
                    dispatchContext(verifyRes);
                } else {
                    dispatchContext(verifyRes);
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };
        init();
    }, []);

    const login = async (username: string, password: string) => {
        try {
            // const response = await axios.post(`${urlSTR}/api/tools/login`, {
            //     // userId: username,
            //     // password,
            //     // org: 'KPR',
            //     // trackingstatus: 'T'
            // });
            const response = await axios.post(`${urlSTR}/loginportal`, {
                user: `${username}AUD`,
                pwd: password
            });
            // console.log(response);
            // const response = await axios.post(`https://api.nitisakc.dev/auth/login`, {
            //     usr: `${username}`,
            //     pwd: password
            // });
            // console.log(response);
            const { status: Ck, error: sText, data: serviceToken } = response.data;
            if (Ck === 'T') {
                const user = jwtDecode<UserProfile>(serviceToken);
                setSession(serviceToken);
                // console.log(user);
                // setRefreshToken(response.headers['x-refresh-token']);
                setRefreshToken(serviceToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user
                    }
                });
            } else {
                throw new Error(sText as string);
            }
        } catch (error) {
            throw new Error(error as string);
        }
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        setRefreshToken(null);
        dispatch({ type: LOGOUT });
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('serviceToken');
        sessionStorage.removeItem('head-data');
        sessionStorage.removeItem('flow-data');
        sessionStorage.removeItem('flow-master');
        sessionStorage.removeItem('file-data');
        // window.localStorage .clear();
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
